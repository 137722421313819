import { ref, computed } from "vue";
import type { Branch } from "~/types/app";

export function useBookingSteps(selectedBranch: Branch | null) {
	const step = ref(0);
	const stepStr = ref('select-branch');
	const checkinDate = ref<Date | null>(null);
	const checkoutDate = ref<Date | null>(null);
	const onlyNight = ref(false);
	const overNight = ref(false)

	const showOnlyNightCheckbox = ref(true);

	const handleOnlyNightSelection = () => {
		step.value++;
	};

	const confirmNextMorningDelivery = () => {
		overNight.value = true
		step.value++;
	};

	const declineNextMorningDelivery = () => {
		step.value++;
	};

	const isCheckoutAfter3PM = computed(() => {
		if (!checkoutDate.value) return false;
		const checkoutHour = checkoutDate.value.getHours();
		return checkoutHour >= 15;
	});

	return {
		step,
		stepStr,
		checkinDate,
		checkoutDate,
		onlyNight,
		overNight,
		showOnlyNightCheckbox,
		handleOnlyNightSelection,
		confirmNextMorningDelivery,
		declineNextMorningDelivery,
		isCheckoutAfter3PM,
	};
}
