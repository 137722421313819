<template>
	<div class="branches-container">
		<div
			v-for="branch in props.branches"
			:key="branch.slug"
			@click="selectBranch(branch)"
			:class="[
				'branch-item p-3 border-round',
				{ selected: branch.slug === selectedBranch?.slug },
			]"
		>
			<div class="branch-info">
				<span class="block text-xl font-medium mb-1">{{ branch.name }}</span>
				<span class="block text-lg">
					{{ $t("Address") }}:
					{{ branch.address || "Placeholder Address" }}</span
				>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref, defineEmits, defineProps, watch } from "vue";
	import type { Branch } from "~/types/app";

	const emit = defineEmits(["branchSelected"]);

	const props = defineProps<{ branches?: Branch[] }>();

	const selectedBranch = defineModel<Branch | null>("modelValue");

	const selectBranch = (branch: Branch) => {
		selectedBranch.value = branch;
	};

	watch(selectedBranch, (branch) => {
		emit("branchSelected", branch);
	});
</script>

<style scoped>
	.branches-container {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100%;
	}

	.branch-item {
		padding: 1rem;
		border: 1px solid #ccc;
		border-radius: 8px;
		cursor: pointer;
		transition: background-color 0.3s, border-color 0.3s, color 0.3s;
	}

	.branch-item.selected {
		background-color: var(--primary-color);
		color: white;
		border-color: var(--primary-color);
	}

	.branch-item:hover {
		background-color: var(--primary-color);
		color: white;
		border-color: var(--primary-color);
	}

	.branch-info {
		display: flex;
		flex-direction: column;
	}
</style>
