<template>
	<div
		class="flex flex-column align-items-center justify-content-center w-full"
	>
		<div class="w-full">
			<div class="time-selector w-full">
				<div
					class="header flex align-items-center justify-content-between w-full p-3"
					style="background-color: #22383d; color: white"
				>
					<span class="text-lg md:text-xl lg:text-2xl font-bold">
						{{ $t("home.bookSection.modal.availableTimesForDay") }}
						: {{ selectedDay }}
					</span>
				</div>
				<div class="times grid w-full mt-3" :style="gridStyle">
					<div
						v-for="(timeSlots, index) in availableTimes"
						:key="index"
						class="time-slot-col"
					>
						<div
							v-for="time in timeSlots"
							:key="time"
							class="time flex align-items-center justify-content-center"
							@click="selectTime(time)"
							:class="{ disabled: isTimeDisabled(time) }"
						>
							{{ time }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref, computed, defineEmits } from "vue";
	import type { Branch, BranchAvailability } from "~/types/app";

	const props = defineProps<{
		branchAvailability: BranchAvailability | null;
		selectedDate: Date | null;
		minTime?: string | null;
		maxTime?: string | null;
	}>();

	const emit = defineEmits(["timeSelected"]);

	const increment = computed(
		() => props.branchAvailability?.increment_minutes ?? 30
	);

	const parseTime = (time: string) => {
		const [hours, minutes] = time.split(":").map(Number);
		return { hours, minutes };
	};

	const getTimeInMinutes = (hours: number, minutes: number) => {
		return hours * 60 + minutes;
	};

	const generateTimeSlots = (start: string, end: string) => {
		const { hours: startHours, minutes: startMinutes } = parseTime(start);
		const { hours: endHours, minutes: endMinutes } = parseTime(end);

		const startTimeInMinutes = getTimeInMinutes(startHours, startMinutes);
		let endTimeInMinutes = getTimeInMinutes(endHours, endMinutes);

		if (
			endTimeInMinutes < startTimeInMinutes ||
			endTimeInMinutes > getTimeInMinutes(21, 0)
		) {
			endTimeInMinutes = getTimeInMinutes(21, 0); // Set end time to 21:00 if it spans overnight or exceeds 21:00
		}

		const slots = [];
		for (
			let time = startTimeInMinutes;
			time <= endTimeInMinutes;
			time += increment.value
		) {
			const hours = Math.floor(time / 60) % 24;
			const minutes = time % 60;
			slots.push(
				`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
			);
		}
		return slots;
	};

	const availableTimes = computed(() => {
		if (!props.branchAvailability?.opening_hours || !props.selectedDate)
			return [];

		const dayOfWeek = props.selectedDate
			.toLocaleString("en-US", { weekday: "long" })
			.toLowerCase() as keyof BranchAvailability["opening_hours"];

		const availability: Array<string> =
			props.branchAvailability.opening_hours[dayOfWeek];

		if (!availability) return [];

		return availability.map((timeRange: string) => {
			const [start, end] = timeRange.split("-");
			return generateTimeSlots(start, end);
		});
	});

	const gridStyle = computed(() => {
		return {
			"grid-template-columns": `repeat(${availableTimes.value.length}, 1fr)`,
			gap: "10px",
		};
	});

	const minTimeInMinutes = computed(() => {
		if (!props.minTime) return null;
		const { hours, minutes } = parseTime(props.minTime);
		return getTimeInMinutes(hours, minutes);
	});

	const maxTimeInMinutes = computed(() => {
		if (!props.maxTime) return null;
		const { hours, minutes } = parseTime(props.maxTime);
		return getTimeInMinutes(hours, minutes);
	});

	const isTimeDisabled = (time: string) => {
		const { hours, minutes } = parseTime(time);
		const timeInMinutes = getTimeInMinutes(hours, minutes);
		if (
			minTimeInMinutes.value !== null &&
			timeInMinutes < minTimeInMinutes.value
		) {
			return true;
		}
		if (
			maxTimeInMinutes.value !== null &&
			timeInMinutes > maxTimeInMinutes.value
		) {
			return true;
		}
		return false;
	};

	const selectTime = (time: string) => {
		if (!isTimeDisabled(time)) {
			emit("timeSelected", time);
		}
	};

	const selectedDay = computed(() => {
		if (!props.selectedDate) return "";
		return props.selectedDate.toLocaleDateString("it-IT", {
			weekday: "long",
			day: "numeric",
			month: "long",
			year: "numeric",
		});
	});
</script>

<style>
	.time-selector {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		overflow: hidden; /* Evita lo scrollbar */
	}

	.header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 10px;
		background-color: #22383d;
		color: white;
	}

	.times {
		display: grid;
		width: 100%;
		margin-top: 1rem;
	}

	.time-slot-col {
		display: flex;
		flex-direction: column;
		gap: 5px; /* Gap tra gli orari */
	}

	.time {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px; /* Riduce l'altezza degli elementi */
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 8px;
		cursor: pointer;
		transition: background-color 0.3s, color 0.3s;
	}

	.time:hover {
		background-color: var(--primary-color);
		color: #22383d;
	}

	.time.selected {
		background-color: var(--primary-color);
		color: #22383d;
	}

	.time.disabled {
		background-color: #eee;
		color: #888;
		cursor: not-allowed;
	}

	button:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	.text-3xl {
		font-size: 1.875rem; /* 30px */
	}

	.text-2xl {
		font-size: 1.5rem; /* 24px */
	}

	.font-bold {
		font-weight: 700;
	}

	.text-primary {
		color: var(--primary-color);
	}

	.text-capitalize {
		text-transform: capitalize;
	}
</style>
