<template>
	<ClientOnly>
		<Dialog
			v-model:visible="modelValue"
			class="full-screen-dialog overflow-hidden p-dialog-maximized"
			:style="{ width: '100%', height: '100%' }"
			modal
			:draggable="false"
		>
			<template #header>
				<h2 v-if="stepStr === 'select-branch'" class="lg:text-3xl font-bold mb-4">
					{{ $t("home.bookSection.modal.headers.selectBranch") }}
				</h2>
				<h2
					v-if="stepStr === 'select-checkin-date'"
					class="lg:text-3xl font-bold mb-4"
				>
					{{ $t("home.bookSection.modal.headers.checkInDate") }}
				</h2>
				<h2
					v-if="stepStr === 'select-checkin-time'"
					class="lg:text-3xl font-bold mb-4"
				>
					{{ $t("home.bookSection.modal.headers.checkInTime") }}
				</h2>
				<h2
					v-if="stepStr === 'select-only-night' && isNextDayAvailable"
					class="lg:text-3xl font-bold mb-4"
				>
					{{ $t("home.bookSection.modal.headers.onlyNight") }}
				</h2>
				<h2
					v-if="stepStr === 'select-checkout-time' && onlyNight"
					class="lg:text-3xl font-bold mb-4"
				>
					{{ $t("home.bookSection.modal.headers.checkOutTime") }}
				</h2>
				<h2
					v-if="stepStr === 'select-checkout-date' && !onlyNight"
					class="lg:text-3xl font-bold mb-4"
				>
					{{ $t("home.bookSection.modal.headers.checkOutDate") }}
				</h2>
				<h2
					v-if="stepStr === 'select-checkout-time' && !onlyNight"
					class="lg:text-3xl font-bold mb-4"
				>
					{{ $t("home.bookSection.modal.headers.checkOutTime") }}
				</h2>
				<h2
					v-if="stepStr === 'select-over-night' && isCheckoutAfter3PM"
					class="lg:text-3xl font-bold mb-4"
				>
					{{ $t("home.bookSection.modal.headers.overNight") }}
				</h2>
			</template>
			<div
				class="bg-primary w-full p-3 md:p-4 border-round my-4 flex flex-column md:flex-row justify-content-between text-white check-section"
			>
				<div class="check-info text-lg lg:text-xl font-bold">
					<p class="text-lg lg:text-2xl font-bold">{{ $t("Check-in") }}:</p>
					<p class="font-semibold">{{ checkinDateFormatted }}</p>
					<p class="font-semibold">{{ branchName }}</p>
				</div>
				<div class="flex align-items-center">
					<i class="pi pi-arrow-right text-lg	lg:text-xl check-info font-bold"></i>
				</div>
				<div class="check-info text-lg lg:text-xl">
					<p class="text-lg lg:text-2xl font-bold">{{ $t("Check-out") }}:</p>
					<p class="font-semibold">{{ checkoutDateFormatted }}</p>
					<p class="font-semibold">{{ branchName }}</p>
				</div>
			</div>
			<div class="flex flex-column w-full h-full">
				<div v-if="stepStr === 'select-branch'" class="w-full">
					<BranchSelector
						v-model="selectedBranch"
						:branches="props.branches"
						@branchSelected="stepStr = 'select-checkin-date'"
					/>
				</div>
				<div v-if="stepStr === 'select-checkin-date'" class="w-full">
					<DateSelector
						v-model="checkinDate"
						:branchAvailabilities="selectedBranch?.branch_availabilities"
						@update:date="stepStr = 'select-checkin-time'"
					/>
				</div>
				<div v-if="stepStr === 'select-checkin-time'" class="w-full">
					<HourSelector
						:branchAvailability="
							getBranchAvailabilityForSelectedDate(checkinDate)
						"
						@timeSelected="handleCheckinTimeSelected"
						:selectedDate="checkinDate"
						:min-time="minCheckinTime"
					/>
				</div>
				<div v-if="stepStr === 'select-checkout-date'" class="w-full">
					<DateSelector
						v-model="checkoutDate"
						:branchAvailabilities="selectedBranch?.branch_availabilities"
						:min-date="minCheckoutDate"
						@update:date="stepStr = 'select-checkout-time'"
					/>
				</div>
				<div v-if="stepStr === 'select-checkout-time'" class="w-full">
					<HourSelector
						:branchAvailability="
							getBranchAvailabilityForSelectedDate(checkoutDate)
						"
						@timeSelected="handleCheckoutTimeSelected"
						:selectedDate="checkoutDate"
						:min-time="checkoutMinTime"
					/>
				</div>
				<div v-if="stepStr === 'select-checkout-time'" class="w-full">
					<HourSelector
						v-if="overNight"
						:branchAvailability="
							getBranchAvailabilityForSelectedDate(checkoutDate)
						"
						@timeSelected="handleCheckoutTimeSelected"
						:selectedDate="checkoutDate"
						:max-time="'13:00'"
					/>
				</div>
			</div>
		</Dialog>
	</ClientOnly>
</template>

<script setup lang="ts">
	import {
		defineProps,
		defineModel,
		computed,
		ref,
		watch,
	} from "vue";
	import { useRouter } from "vue-router";
	import Dialog from "primevue/dialog";
	import { useBookingSteps } from "~/composables/useBookingSteps";
	import { useLocalePath} from "#imports";
	import DateSelector from "~/components/Page/Book/Check/DateSelector.vue";
	import HourSelector from "~/components/Page/Book/Check/HourSelector.vue";
	import BranchSelector from "~/components/Page/Book/Check/BranchSelector.vue";
	import type { Branch, BranchAvailability, OpeningHour } from "~/types/app";
	import { format } from "date-fns";

	type DayOfWeek =
		| "monday"
		| "tuesday"
		| "wednesday"
		| "thursday"
		| "friday"
		| "saturday"
		| "sunday";

	const localePath = useLocalePath();

	const props = defineProps<{ branches?: Branch[] }>();
	const modelValue = defineModel<boolean>("modelValue");
	const router = useRouter();

	const selectedBranch = ref<Branch | null>(null);

	watch(modelValue, (newValue) => {
		console.log("modelValue changed:", newValue);
		if (!newValue) {
			step.value = 0;
			stepStr.value = "select-branch";
			selectedBranch.value = null;
			checkinDate.value = null;
			checkoutDate.value = null;
			onlyNight.value = false;
			overNight.value = false;
		}
	});

	const formatISO = (date: Date | string): string => {
		return new Date(date).toISOString();
	};

	const {
		step,
		stepStr,
		checkinDate,
		checkoutDate,
		onlyNight,
		overNight,
		confirmNextMorningDelivery,
		declineNextMorningDelivery,
	} = useBookingSteps(selectedBranch.value);

	const handleCheckinTimeSelected = (time: string) => {
		console.log("Check-in time selected:", time);
		if (checkinDate.value) {
			const [hours, minutes] = time.split(":").map(Number);
			const newDate = new Date(checkinDate.value);
			newDate.setHours(hours, minutes);
			checkinDate.value = newDate;
			stepStr.value = "select-checkout-date";
		}
	};

	const handleCheckoutTimeSelected = (time: string) => {
		console.log("Check-out time selected:", time);
		if (checkoutDate.value) {
			const [hours, minutes] = time.split(":").map(Number);
			const newDate = new Date(checkoutDate.value);
			newDate.setHours(hours, minutes);
			checkoutDate.value = newDate;
			stepStr.value = "confirm";
			confirmBooking();
		}
	};

	const nextStep = () => {
		console.log("Advancing to next step");
		// if (step.value === 3) {
		// step.value = 5;
		// } else {
		step.value++;
		// }
	};

	// const isCheckinDateAfterEighteen

	const isNextDayAvailable = computed(() => {
		console.log(
			"Checking if next day is available with morning hours between 05:00 and 14:00"
		);
		if (!checkinDate.value) return false;

		const nextDay = new Date(checkinDate.value);
		nextDay.setDate(nextDay.getDate() + 1);
		if (!selectedBranch.value || !selectedBranch.value.branch_availabilities)
			return false;

		const dayOfWeek = nextDay
			.toLocaleString("en-US", { weekday: "long" })
			.toLowerCase() as keyof BranchAvailability["opening_hours"];

		const availability = selectedBranch.value.branch_availabilities.find(
			(availability) => {
				if (!availability.opening_hours) return false;
				const openingHours: OpeningHour = availability.opening_hours[dayOfWeek];
				if (!Array.isArray(openingHours) || openingHours.length === 0)
					return false;

				return openingHours.some((hour) => {
					const [start, end] = hour.split("-").map((time: string) => {
						const [hours, minutes] = time.split(":").map(Number);
						return new Date(nextDay.setHours(hours, minutes));
					});
					return start.getHours() >= 5 && end.getHours() <= 14;
				});
			}
		);

		return !!availability;
	});

	const minCheckoutDate = computed(() => {
		if (checkinDate.value) {
			const branchAvailability = getBranchAvailabilityForSelectedDate(
				checkinDate.value
			);
			console.log("checkinDate.value");
			if (branchAvailability && branchAvailability.opening_hours) {
				console.log("has a branch availability");
				const dayOfWeek = checkinDate.value
					.toLocaleString("en-US", { weekday: "long" })
					.toLowerCase() as DayOfWeek;
				const availability = branchAvailability.opening_hours[dayOfWeek];

				console.log(availability);

				if (availability) {
					const [firstTime, lastTime] =
						availability[availability.length - 1].split("-");
					console.log(lastTime);
					const lastHour = parseInt(lastTime.split(":")[0], 10);
					const checkinHour = checkinDate.value.getHours();

					console.log("checkinHour", checkinHour, "last hour", lastHour);

					if (checkinHour >= lastHour - 1) {
						const nextDay = new Date(checkinDate.value);
						nextDay.setDate(nextDay.getDate() + 1);
						nextDay.setHours(10);
						nextDay.setMinutes(0);
						return nextDay;
					}
				}
			}
			return checkinDate.value;
		}
		return null;
	});

	// const minCheckoutDate = computed(() => {
	// 	if (checkinDate.value) {
	// 		const branchAvailability = getBranchAvailabilityForSelectedDate(
	// 			checkinDate.value
	// 		);
	// 		console.log("checkinDate.value");
	// 		if (branchAvailability) {
	// 			console.log("has a branch availability");
	// 			const dayOfWeek = checkinDate.value
	// 				.toLocaleString("en-US", { weekday: "long" })
	// 				.toLowerCase();
	// 			if (!branchAvailability.opening_hours) return null;
	// 			const availability = branchAvailability.opening_hours[dayOfWeek];

	// 			console.log(availability);

	// 			if (availability) {
	// 				const [firstTime, lastTime] =
	// 					availability[availability.length - 1].split("-");
	// 				console.log(lastTime);
	// 				const lastHour = parseInt(lastTime.split(":")[0], 10);
	// 				const checkinHour = checkinDate.value.getHours();

	// 				console.log("checkinHour", checkinHour, "last hour", lastHour);

	// 				if (checkinHour >= lastHour - 1) {
	// 					const nextDay = new Date(checkinDate.value);
	// 					nextDay.setDate(nextDay.getDate() + 1);
	// 					nextDay.setHours(10);
	// 					nextDay.setMinutes(0);
	// 					return nextDay;
	// 				}
	// 			}
	// 		}
	// 		return checkinDate.value;
	// 	}
	// 	return null;
	// });

	const minCheckinTime = computed(() => {
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Reset the time to midnight for comparison

		if (
			checkinDate.value &&
			checkinDate.value.toDateString() === today.toDateString()
		) {
			const newDate = new Date();
			newDate.setHours(newDate.getHours() + 1, 0, 0, 0); // Add 1 hour and reset minutes and seconds
			const hours = newDate.getHours();
			const minutes = newDate.getMinutes();
			return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
				2,
				"0"
			)}`;
		}

		return "08:00";
	});

	const checkoutMinTime = computed(() => {
		if (
			checkinDate.value &&
			checkoutDate.value &&
			checkinDate.value.toDateString() === checkoutDate.value.toDateString()
		) {
			const newDate = new Date(checkinDate.value);
			newDate.setHours(newDate.getHours() + 1);
			const hours = newDate.getHours();
			const minutes = newDate.getMinutes();
			return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
				2,
				"0"
			)}`;
		}
		return "08:00";
	});

	// const isNextDayAvailable = computed(() => {
	// 	console.log("Checking if next day is available");
	// 	if (!checkinDate.value) return false;

	// 	const nextDay = new Date(checkinDate.value);
	// 	nextDay.setDate(nextDay.getDate() + 1);
	// 	if (!selectedBranch.value || !selectedBranch.value.branch_availabilities)
	// 		return false;

	// 	const dayOfWeek = nextDay
	// 		.toLocaleString("en-US", { weekday: "long" })
	// 		.toLowerCase() as keyof BranchAvailability["opening_hours"];

	// 	return selectedBranch.value.branch_availabilities.some((availability) => {
	// 		if (!availability.opening_hours) return false;
	// 		const openingHours: OpeningHour = availability.opening_hours[dayOfWeek];
	// 		return Array.isArray(openingHours) && openingHours.length > 0;
	// 	});
	// });
	const isNextCheckoutDayAvailable = computed(() => {
		console.log("Checking if next day is available");
		if (!checkoutDate.value) return false;

		const nextDay = new Date(checkoutDate.value);
		nextDay.setDate(nextDay.getDate() + 1);
		if (!selectedBranch.value || !selectedBranch.value.branch_availabilities)
			return false;

		const dayOfWeek = nextDay
			.toLocaleString("en-US", { weekday: "long" })
			.toLowerCase() as keyof BranchAvailability["opening_hours"];

		return selectedBranch.value.branch_availabilities.some((availability) => {
			if (!availability.opening_hours) return false;
			const openingHours: OpeningHour = availability.opening_hours[dayOfWeek];
			return Array.isArray(openingHours) && openingHours.length > 0;
		});
	});

	const handleOnlyNightSelection = (onlyNightSelected: boolean) => {
		console.log("Only night selected:", onlyNightSelected);
		onlyNight.value = onlyNightSelected;
		if (onlyNightSelected) {
			const nextDay = new Date(checkinDate.value!);
			nextDay.setDate(nextDay.getDate() + 1);
			checkoutDate.value = nextDay;
			step.value = 4; // Skip to check-out time selection
		} else {
			nextStep();
		}
	};

	const handleOverNightSelection = (overNightSelected: boolean) => {
		console.log("Over night selected:", overNightSelected);
		overNight.value = true;
		if (overNight.value) {
			const nextDay = new Date(checkoutDate.value!);
			nextDay.setDate(nextDay.getDate() + 1);
			console.log("setting next day from :", nextDay.getDate());
			console.log("setting next day to :", nextDay.getDate() + 1);
			checkoutDate.value = nextDay;
			step.value = 7; // Skip to check-out time selection
		} else {
			nextStep();
		}
	};

	const getBranchAvailabilityForSelectedDate = (date: Date | null) => {
		console.log("Getting branch availability for selected date:", date);
		if (
			!date ||
			!selectedBranch.value ||
			!selectedBranch.value.branch_availabilities
		)
			return null;

		const dayOfWeek = date
			.toLocaleString("en-US", { weekday: "long" })
			.toLowerCase() as keyof BranchAvailability["opening_hours"];

		return (
			selectedBranch.value.branch_availabilities.find((availability) => {
				if (!availability.opening_hours) {
					return false;
				}
				return dayOfWeek in availability.opening_hours;
			}) || null
		);
	};

	const checkinDateFormatted = computed(() => {
		return checkinDate.value ? checkinDate.value.toLocaleString() : "";
	});

	const checkoutDateFormatted = computed(() => {
		return checkoutDate.value ? checkoutDate.value.toLocaleString() : "";
	});

	const branchName = computed(() => {
		return selectedBranch.value ? selectedBranch.value.name : "";
	});

	const isCheckoutAfter3PM = computed(() => {
		console.log("Checking if checkout is after 3PM");
		if (!checkoutDate.value) return false;
		const checkoutHour = checkoutDate.value.getHours();
		return checkoutHour >= 15;
	});

	const confirmBooking = () => {
		console.log("Confirming booking");
		if (selectedBranch.value && checkinDate.value && checkoutDate.value) {
			const checkinFormatted = formatCustom(new Date(checkinDate.value));
			const checkoutFormatted = formatCustom(new Date(checkoutDate.value));
			console.log(checkinFormatted);
			console.log(checkoutFormatted);
			book(selectedBranch.value.slug, checkinFormatted, checkoutFormatted);
		}
	};

	// const confirmBooking = () => {
	// 	console.log("Confirming booking");
	// 	if (selectedBranch.value && checkinDate.value && checkoutDate.value) {
	// 		const checkinFormatted = format(
	// 			new Date(checkinDate.value),
	// 			"yyyy-MM-dd HH:mm"
	// 		);
	// 		const checkoutFormatted = format(
	// 			new Date(checkoutDate.value),
	// 			"yyyy-MM-dd HH:mm"
	// 		);
	// 		console.log(checkinFormatted);
	// 		console.log(checkoutFormatted);
	// 		book(
	// 			selectedBranch.value.slug,
	// 			checkinDate.value.toISOString(),
	// 			checkoutDate.value.toISOString()
	// 		);
	// 	}
	// };

	const formatCustom = (date: Date | string): string => {
		return format(new Date(date), "yyyy-MM-dd HH:mm");
	};

	const book = (branch: string, checkin_at: string, checkout_at: string) => {
		console.log("Booking:", { branch, checkin_at, checkout_at });
		const path = localePath({
			name: "book-branch",
			params: { branch },
			query: {
				// checkin_at: formatISO(new Date(checkin_at)),
				// checkout_at: formatISO(new Date(checkout_at)),
				checkin_at: checkin_at,
				checkout_at: checkout_at,
			},
		});
		router.push(path);
	};
</script>

<style scoped>
	.full-screen-dialog .p-dialog {
		width: 100% !important;
		max-width: none !important;
		height: 100% !important;
		top: 0 !important;
		left: 0 !important;
	}
	.full-screen-dialog .p-dialog-content {
		height: 100% !important;
		overflow: hidden !important;
	}

	.check-info {
		color: #22383d;
	}

	/* Prevent body scrolling when dialog is open */
	.body-lock {
		overflow: hidden;
	}
</style>
