<script setup lang="ts">
	import type { Branch, JsonResource } from "~/types/app.ts";
	import { ref } from "vue";
	import Dropdown from "primevue/dropdown";
	import Calendar from "primevue/calendar";
	import Button from "primevue/button";
	import { useLocalePath } from "#imports";
	import { useRouter } from "vue-router";
	import DateTimeDialog from "../Book/DateTimeDialog.vue";
	import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
	import { library } from "@fortawesome/fontawesome-svg-core";
	import {
		faRoad,
		faHelmetSafety,
		faMoneyBillWave,
		faShieldHalved,
		faHeadset,
	} from "@fortawesome/free-solid-svg-icons";

	library.add(
		faRoad,
		faHelmetSafety,
		faMoneyBillWave,
		faShieldHalved,
		faHeadset
	);
	const props = defineProps<{ branches: JsonResource<Branch[]> }>();
	const form = ref({
		branch: null,
		checkin_at: "",
		checkout_at: "",
	});
	const localePath = useLocalePath();
	const router = useRouter();

	const formatISO = (date: Date | string): string => {
		return new Date(date).toISOString();
	};

	const book = (branch: string, checkin_at: string, checkout_at: string) => {
		return;
		const path = localePath({
			name: "book-branch",
			params: { branch: branch },
			query: {
				checkin_at: formatISO(checkin_at),
				checkout_at: formatISO(checkout_at),
			},
		});
		router.push(path);
	};

	const isVisible = ref(false);
</script>
<template>
	<DateTimeDialog v-model="isVisible" :branches="branches?.data" />
	<div
		id="book-section"
		class="flex flex-column p-6 shadow-xl"
		style="background: #22383d"
	>
		<div
			class="flex flex-column md:flex-row justify-content-center text-2xl align-items-center gap-8 text-white my-4"
		>
			<div
				class="flex flex-column text-center gap-4 justify-content-center font-semibold align-content-center"
			>
				<div>
					<FontAwesomeIcon size="3x" icon="fa-solid fa-shield-halved" />
				</div>
				<div class="text-center uppercase">
					{{ $t("home.bookSection.form.svg.kaskoAvailable") }}
				</div>
			</div>
			<div
				class="flex flex-column text-center gap-4 justify-content-center font-semibold align-content-center"
			>
				<div>
					<FontAwesomeIcon size="3x" icon="fa-solid fa-headset" />
				</div>
				<div class="text-center uppercase">
					{{ $t("home.bookSection.form.svg.assistance") }}
				</div>
			</div>
			<div
				class="flex flex-column text-center gap-4 justify-content-center font-semibold align-content-center"
			>
				<div>
					<FontAwesomeIcon size="3x" icon="fa-solid fa-money-bill-wave" />
				</div>
				<div class="text-center uppercase">
					{{ $t("home.bookSection.form.svg.bestPrice") }}
				</div>
			</div>
			<div
				class="flex flex-column text-center gap-4 justify-content-center font-semibold align-content-center"
			>
				<div>
					<FontAwesomeIcon size="3x" icon="fa-solid fa-road text-4xl" />
				</div>
				<div class="text-center uppercase">
					{{ $t("home.bookSection.form.svg.unlimitedKm") }}
				</div>
			</div>

			<div
				class="flex flex-column text-center gap-4 justify-content-center font-semibold align-content-center"
			>
				<div>
					<font-awesome-icon size="3x" icon="fa-solid fa-helmet-safety" />
				</div>
				<div class="text-center uppercase">
					{{ $t("home.bookSection.form.svg.twoHelmets") }}
				</div>
			</div>
		</div>
		<div
			class="flex justify-content-center py-2 text-primary font-bold text-2xl"
			v-if="props.branches.meta?.batch_bookings_count > 0 && false"
		>
			{{
				$t("home.bookSection.pendingBooking.plural", {
					count: props.branches.meta?.batch_bookings_count,
				})
			}}
		</div>
		<div class="flex justify-content-center pt-4 gap-4">
			<div
				class="flex flex-column md:flex-row gap-4 justify-content-between align-items-center"
			>
				<div>
					<label for="calendar-12h" class="text-white block mb-2">
						{{ $t("home.bookSection.form.where.label") }}
					</label>
					<Dropdown
						size="large"
						@click.prevent="isVisible = true"
						optionValue="slug"
						optionLabel="short_name"
						class="w-16rem"
						:placeholder="$t('home.bookSection.form.where.placeholder')"
					/>
				</div>
				<div>
					<label for="calendar-12h" class="text-white block mb-2">
						{{ $t("home.bookSection.form.checkIn.label") }}
					</label>

					{{ form.checkin_at }}
					<Calendar
						size="large"
						@click.prevent="isVisible = true"
						showIcon
						:showOnFocus="false"
						:stepMinute="30"
						showTime
						touchUI
						class="w-16rem"
						:placeholder="$t('home.bookSection.form.checkIn.placeholder')"
						date-format="dd/mm/yy"
					/>
				</div>
				<div>
					<label for="calendar-12h" class="text-white block mb-2">
						{{ $t("home.bookSection.form.checkOut.label") }}
					</label>

					{{ form.checkout_at }}

					<Calendar
						size="large"
						@click.prevent="isVisible = true"
						:showOnFocus="false"
						:stepMinute="30"
						showIcon
						showTime
						class="w-16rem"
						touchUI
						:placeholder="$t('home.bookSection.form.checkOut.placeholder')"
						date-format="dd/mm/yy"
					/>
				</div>
				<div>
					<label for="calendar-12h" class="text-white block mb-2">
						&nbsp;
					</label>
					<Button
						:disabled="!form.branch || !form.checkin_at || !form.checkout_at"
						:label="$t('home.bookSection.form.continue')"
						icon="pi pi-arrow-right"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
